/** ------------------------------
    Podcast card
    ------------------------------ */
.podcast-feature-card {
    background-color: #ededed;

    @media (width >= 768px) {
        display: flex;
        }
    }
    .podcast-feature-card__media {
        @mixin framed;
        overflow: hidden;
        position: relative;
        flex: 0 0 30%;

        @media (width < 768px) {
            padding-bottom: 80%;
            }

        & img {
            position: absolute;
            height: calc(100% - 1rem);
            left: 50%;
            bottom: 0;
            max-width: none;
            transform: translateX(-50%);

            @supports (object-fit: cover) {
                width: 100%;
                object-fit: cover;
                object-position: 50% 0;
                }

            }
        }
    .podcast-feature-card__body {
        flex-grow: 1;
        padding: 2rem;
        background-color: #0064a8;
        color: #3cb4e5;

        @media (width <= 600px) {
            padding: 1rem;
            }

        & .callout {
            color: #fff;
            }

        & .btn-group {
            margin-top: 1.5rem;

            & > :first-child {
                min-width: 200px;
                }
            }
        }
        .podcast-feature-card__title {
            font-size: 1.75rem;
            color: #fff;
            text-transform: none;

            @media (width < 768px) {
                font-size: 1.5rem;
                }
            }
        .podcast-feature-card__speaker-name {
            font-size: 1.125rem;
            font-weight: 800;
            color: #3cb4e5;
            text-transform: uppercase;
            }
        .podcast-feature-card__speaker-title {
            font-size: 1.125rem;
            font-weight: 400;
            }



