/** ------------------------------
    Sidebar layout
    ------------------------------ */
@media (width >= 740px) {

    .sidebar-layout {
        display: flex;
        }
        .sidebar-layout__menu {
            flex: 0 0 220px;
            }
        .sidebar-layout__body {
            padding-left: calc(var(--grid-gutter) / 2);
            flex-grow: 1;
            }

}
