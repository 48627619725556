/** ------------------------------
    Card
    ------------------------------ */
.card {
    position: relative;

    &:matches(:hover, :focus-within) a {
        text-decoration: underline;
        }
    }
    .card__body {
        padding-top: .5rem;
        }
    .card__title {
        & a {
            text-decoration: none;

            /* Pseduo link covering entire card */
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                }
            }
        }


/** ------------------------------
    Card grid
    ------------------------------ */
.card-grid {
    }
    .card-grid__item {
        margin: 1.5rem 0;
        }

@media (width >= 600px) {

    .card-grid {
        @mixin grid-container;
        display: flex;
        flex-wrap: wrap;
        }
        .card-grid__item {
            @mixin grid-item;
            flex: 0 0 50%;
            }

}

@media (width >= 1000px) {

    .card-grid__item {
        flex-basis: 25%;
        }

}
