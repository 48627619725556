/** ------------------------------
    Section
    ------------------------------ */
.section {
    margin: 4rem 0;
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.section--padded {
    margin: 0;
    padding: 4rem 0;
    }
