/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }


/** ------------------------------
    Layout
    ------------------------------ */
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    }
    .main-content {
        flex-grow: 1;
        }


/** ------------------------------
    Containers
    ------------------------------ */
@define-mixin container {
    margin: auto;
    @mixin container-gutters;
    @mixin container-width;
}

@define-mixin container-gutters {
    padding: 0 calc(var(--container-gutter) / 2);

    @media (width < 500px) {
        padding: 0 4%;
        }
}

@define-mixin container-width {
    max-width: var(--container-width);
}

.container {
    @mixin container;
    }

.container--md {
    max-width: calc(var(--container-width) * .8);
    }

.container--sm {
    max-width: calc(var(--container-width) * .6);
    }

.container--fluid {
    max-width: none;
    }


/** ------------------------------
    Skip link
    ------------------------------ */
.skip-link {
    @mixin visually-hidden;
    background-color: #000;
    color: #fff;
    text-decoration: none;

    &:focus {
        padding: 1em;
        margin: 0;
        width: auto;
        height: auto;
        clip: auto;
        }
    }
