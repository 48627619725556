/** ------------------------------
    Intro columns
    ------------------------------ */
@media (width >= 740px) {

    .intro {
        display: flex;
        }
        .intro__lead {
            flex: 0 0 40%;

            & > * {
                max-width: 30ch;
                }
            }
        .intro__body {
            padding-left: 2rem;
            flex-grow: 1;
            }

}
