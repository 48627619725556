/** ------------------------------
    Brand colours
    ------------------------------ */
.fg-brand-primary {
    color: var(--brand-primary);
    }

.fg-brand-secondary {
    color: var(--brand-secondary);
    }

.fg-brand-light {
    color: var(--brand-light);
    }

.fg-brand-dark {
    color: var(--brand-dark);
    }

.fg-brand-red {
    color: var(--brand-red);
    }

.fg-white {
    color: #fff;
    }

.bg-brand-primary {
    background-color: var(--brand-primary);
    }

.bg-brand-secondary {
    background-color: var(--brand-secondary);
    }

.bg-brand-light {
    background-color: var(--brand-light);
    }

.bg-brand-dark {
    background-color: var(--brand-dark);
    }

.bg-brand-gradient {
    background: linear-gradient(90deg, #3cb4e5 40%, rgba(60, 180, 229, .7) 60%);
    }


/** ------------------------------
    Alignments
    ------------------------------ */
@define-mixin center-block {
    margin-left: auto;
    margin-right: auto;
}

.center-block {
    @mixin center-block;
    }

@define-mixin align $alignment, $force: false {
    @if $force == true {
        text-align: $alignment !important;
    } @else {
        text-align: $alignment;
    }
}

@define-mixin pull $alignment, $force: false {
    @if $force == true {
        float: $alignment !important;
    } @else {
        float: $alignment;
    }
}

.align-center {
    @mixin align center, true;
    }

.align-left {
    @mixin align left, true;
    }

.align-right {
    @mixin align right, true;
    }

.pull-left {
    @mixin pull left, true;
    }

.pull-right {
    @mixin pull right, true;
    }

.pull-none {
    @mixin pull none, true;
    }


/** ------------------------------
    Layout
    ------------------------------ */
.no-margin {
    margin: 0 !important;
    }

.no-padding {
    padding: 0 !important;
    }


/** ------------------------------
    Visibility
    ------------------------------ */
[hidden],
.hidden {
    display: none !important;
    }

@define-mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.visually-hidden {
    @mixin visually-hidden;
    }
