/** ------------------------------
    Responsive Media
    ------------------------------ */
.responsive-media {
    position: relative;
    padding-bottom: calc(9 / 16 * 100%);

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.responsive-media--4x3 {
    padding-bottom: calc(3 / 4 * 100%);
    }

.responsive-media--1x1 {
    padding-bottom: 100%;
    }
