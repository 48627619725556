/** ------------------------------
    Mixins
    ------------------------------ */

/*
    Only place global mixins here.
    Component-specific mixins such as `button` should be in
    the relevant component file.
 */

@define-mixin base-margin $scale: 1 {
    margin-top: 0;
    margin-bottom: calc(var(--base-rhythm) * $scale);
}

/* Grid mixins are used for layouts that a similar to grids, but not a lazy .grid component */
@define-mixin grid-container {
    margin-left: calc(var(--grid-gutter) / -2);
    margin-right: calc(var(--grid-gutter) / -2);
}

@define-mixin grid-item {
    padding-left: calc(var(--grid-gutter) / 2);
    padding-right: calc(var(--grid-gutter) / 2);
}

@define-mixin button-reset {
    appearance: none;
    padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
}

@define-mixin list-reset {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

@define-mixin framed {
    position: relative;

    &::after,
    &::before {
        content:"";
        position: absolute;
        width: 40px;
        height: 40px;
        border-top: 3px solid var(--brand-red);
        border-right: 3px solid var(--brand-red);
        z-index: 25;

        @media (width >= 768px) {
            width: 60px;
            height: 60px;
            border-top-width: 5px;
            border-right-width: 5px;
            }
        }

    &::before {
        top: 1.5rem;
        right: 1.5rem;

        @media (width <= 600px) {
            top: 1rem;
            right: 1rem;
            }
        }

    &::after {
        bottom: 1.5rem;
        left: 1.5rem;
        transform: rotate(-180deg);

        @media (width <= 600px) {
            bottom: 1rem;
            left: 1rem;
            }
        }

}
