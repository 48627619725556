/** ------------------------------
    Podcast Intro
    ------------------------------ */
.podcast-intro {
    }
    .podcast-intro__body {
        }
    .podcast-intro__feature {
        margin-top: 2rem;
        }

@media (width >= 800px) {

    .podcast-intro {
        display: flex;
        }
    .podcast-intro__body {
        flex-basis: 33%;
        }
    .podcast-intro__feature {
        margin-top: 0;
        padding-left: calc(var(--grid-gutter) / 2);
        flex-basis: 67%;
        }

}

@media (width >= 1000px) {

    .podcast-intro__feature {
        padding-left: var(--grid-gutter);
        }

}
