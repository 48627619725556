/** ------------------------------
    Podcast card
    ------------------------------ */
.podcast-card {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1rem 1rem;
    height: 100%;
    background-color: #042641;
    color: #3cb4e5;
    }
    .podcast-card__title {
        color: #fff;
        text-transform: none;
        }
    .podcast-card__author-list {
        margin-bottom: 1.5rem;
        font-size: .875rem;
        font-weight: 400;

        & > :not(:first-child) {
            margin-top: 1rem;
            }

        & + * {
            margin-top: auto;
            }
        }
        .podcast-card__author-name {
            text-transform: uppercase;
            font-weight: 800;
            }


/** ------------------------------
    Grid
    ------------------------------ */
.podcast-card-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -.75rem;
    }
    .podcast-card-grid__item {
        width: 25%;
        padding: 0 .75rem 1.5rem;

        @media (width < 980px) {
            width: 33.333%;
            }

        @media (width < 720px) {
            width: 50%;
            }

        @media (width < 500px) {
            width: 100%;
            }
        }
