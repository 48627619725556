/** ------------------------------
    Panel
    ------------------------------ */
.panel {
    margin-top: 3rem;
    background-color: #fff;
    }
    .panel__header {
        padding: .875rem;
        background-color: #EAEAEA;
        }
        .panel__title {
            font-weight: 800;
            color: #54565B;
            text-transform: uppercase;
            }
    .panel__body {
        padding: 2rem 1.5rem;

        @media (width >= 768px) {
            padding: 3rem;
            }

        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.panel--light {
    background-color: #eaeaea;
    color: var(--brand-primary);
    }

.panel--dark {
    background-color: var(--brand-primary);
    color: #fff;
    }

.panel--shallow {

    & .panel__body {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        }

    }
