/** ------------------------------
    Footer
    ------------------------------ */
.site-footer {
    padding: 2.5rem 0;
    background: var(--brand-primary);
    color: #fff;
    font-size: 0.75rem;

    & p {
        margin-top: 1em;
        }
    }
    .site-footer__grid {
        @mixin container;

        @media (width >= 1140px) {
            display: flex;
            align-items: center;
            }

        }

    /* Nav */
    .site-footer__menu {
        margin-bottom: 2.5rem;
        }
        .site-footer__nav {
            font-size: 0.875rem;
            margin-bottom: 0;

            @media (width >= 768px) {
                display: flex;
                }
            }
            .site-footer__nav-item {
                margin: .5rem 0;

                @media (width >= 768px) {
                    display: inline-flex;
                    line-height: 1;

                    &:not(:last-child) {

                        &::after {
                            margin: 0 .75em;
                            content: "|";
                            }

                        }
                    }

                & a {
                    text-transform: uppercase;
                    color: #fff;
                    text-decoration: none;
                    font-weight: 800;

                    &:hover {
                        text-decoration: underline;
                        }
                    }
                }

    /* Partners */
    .site-footer__partners {
        margin-top: 2rem;

        & img {
            display: inline-block;
            height: 32px;

            &:not(:first-child) {
                margin-left: 1.5rem;
                }
            }

        @media (width >= 1140px) {
            margin-top: 0;
            margin-left: auto;
            flex-basis: 260px;
            }

        }
