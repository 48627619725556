/** ------------------------------
    Page menu
    ------------------------------ */
.page-menu {
    margin-bottom: 3rem;

    & a {
        display: block;
        padding: 1rem;
        background: #EAEAEA;
        color: var(--brand-primary);
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            background: var(--brand-primary);
            color: #fff;
            }
        }
    }
    .page-menu__nav-item {

        &:not(:first-child) {
            border-top: 1px solid #C6CAC6;
            }

        /* Active state */
        &.current-menu-item {

            & a {
                background: var(--brand-primary);
                color: #fff;
                }

            }

        }
