/** ------------------------------
    Block CTA
    ------------------------------ */
.block-cta {
    margin: 3rem auto;
    max-width: calc(var(--container-width) - var(--container-gutter));
    color: #fff;
    }
    .block-cta__container {
        padding: 1.5rem;
        }
    .block-cta__copy {
        font-size: 1.5rem;
        font-weight: 800;
        line-height: 1.2;

        @media (width < 768px) {
            font-size: 1.375rem;
            }
        }
    .block-cta__btn {

        @media (width < 768px) {
            margin-top: 1.5rem;
            }

        }

@media (width >= 768px) {

    .block-cta__container {
        display: flex;
        align-items: center;
        }
        .block-cta__copy {
            flex-basis: 55%;
            }
        .block-cta__btn {
            margin-left: auto;
            flex-basis: 50%;
            }

}


/** ------------------------------
    Modifiers
    ------------------------------ */
.block-cta--full-width {
    margin: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: none;

    & .block-cta__container {
        @mixin container;
        }
    }
