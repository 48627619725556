/** ------------------------------
    Page Hero
    ------------------------------ */
.media-hero {
    position: relative;
    color: #fff;
    background: linear-gradient(90deg, #3cb4e5 30%, rgba(60, 180, 229, .7) 55%);
    overflow: hidden;
    }
    .media-hero__container {
        @mixin container;
        position:  relative;
        padding-top: 1rem;
        padding-bottom: 2.5rem;

        @media (width >= 768px) {
            text-align: right;
            }
        }
    .media-hero__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        & > img {
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            max-width: none;
            height: 100%;
            mix-blend-mode: darken;
            }
        }
    .media-hero__body {
        padding-top: 2rem;
        flex-grow: 1;

        & > * {
            max-width: 600px;
            }

        @media (width >= 768px) {
            display: inline-block;
            padding-top: 2rem;
            width: 38%;
            text-align: left;
            }
        }

    .media-hero__title {
        font-size: 2.5rem;
        font-weight: 800;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;

        & span {
            color: var(--brand-primary);

            &:first-child {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                display: block;
                }
            }
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.media-hero--reversed {
    background: linear-gradient(270deg, #3cb4e5 30%, rgba(60, 180, 229, .1) 55%);

    & .media-hero__media > img {
        position: absolute;
        left: 0;
        right: auto;
        }

    }
