/** ------------------------------
    Base
    ------------------------------ */
@define-mixin form-control {
    display: block;
    width: 100%;
    margin: var(--form-control-margin);
    padding: 0 var(--form-control-padding);
    height: var(--form-control-height);
    line-height: var(--form-control-height);
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    font-family: var(--btn-font-family);
    font-weight: 800;
    color: var(--form-control-default-color);
    appearance: none; /* Remove iOS border-radius */

    &:focus {
        outline: none;
        border-color: var(--form-control-default-border-color-active);
        }

    &::placeholder {
        text-transform: uppercase;
        }
}

@define-mixin form-label {
    display: inline-block;
    color: var(--brand-primary);
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 800;

    &.required {

        &::after {
            content: " *";
            color: var(--brand-red);
            }

        }
}

.form,
.form__group {
    @mixin base-margin;
    margin-bottom: 2.5rem;
    }
    .form__container {
        max-width: 700px;

        &.form__container--flush .form__group {
            margin-bottom: 2rem;
            }
        }
    .form__label {
        @mixin form-label;
        }
    .form__control {
        @mixin form-control;

        /* Colour pickers don't need to be 100% */
        &[type="color"] {
            width: calc(var(--form-control-height) + 1rem);
            }
        }
    /* Fix padding of multi-line fields */
    textarea.form__control,
    select.form__control[multiple] {
        padding: var(--form-control-padding);
        height: auto;
        line-height: var(--base-line-height);
        }
    /* Help text */
    .form__help {
        margin: .5rem 0 0;
        max-width: 540px;
        font-size: .6875rem;
        font-weight: 800;
        color: #687d8d;
        text-transform: uppercase;
        }
    .form__conditions {
        margin-bottom: 5rem;
        }


/** ------------------------------
    Group rows
    ------------------------------ */
.form__group-row {
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;

    & > .form__group {
        flex: 1 1 300px;
        padding-left: 1rem;
        padding-right: 1rem;
        }

    @media (width >= 680px) {

        & > .form__group--slim {
            flex-basis: 200px;
            flex-grow: 0;
            }

        }
    }


/** ------------------------------
    Form button
    ------------------------------ */
.form__btn {
    margin-top: 2rem;

    &.form__btn--left {
        text-align: left;
        margin-bottom: 3.5rem;
        }

    & .btn {
        width: 100%;
        max-width: 325px;
        }
    }


/** ------------------------------
    Form links
    ------------------------------ */
.form__link {
    display: inline-block;
    margin-top: 1rem;
    color: var(--brand-primary);

    &:matches(:focus, :hover) {
        color: var(--brand-red);
        }
    }


/** ------------------------------
    Notices
    ------------------------------ */
.notice {
    padding: 1em;
    color: #fff;
    }

.notice--error {
    background-color: var(--brand-red);
    }

.notice--success {
    background-color: var(--brand-secondary);
    }
