/** ------------------------------
    Tabs
    ------------------------------ */
.tabs {
    display: flex;
    height: 75px;

    @media (height < 600px) {
        height: 65px;
        }
    }
    .tabs__item {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 6px;
        border-bottom: 6px solid transparent;
        background-color: rgba(234,234,234, 0.8);
        color: #0064a8;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 800;

        &:matches(:hover, .tabs__item--active) {
            background: #fff;
            border-bottom-color: var(--brand-red);
            color: var(--brand-secondary);
            }
        }
