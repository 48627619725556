/** ------------------------------
    Podcast plaer
    ------------------------------ */
.podcast-info {
    font-size: 0.875rem;
    margin-bottom: 2rem;
    }
    .podcast-info__author-list {
        margin-bottom: 2.5rem;
        font-size: .875rem;
        font-weight: 400;
        color: #3cb4e5;

        & > :not(:first-child) {
            margin-top: 1rem;
            }
        }
        .podcast-info__author-name {
            text-transform: uppercase;
            font-weight: 800;
            }

    .podcast-info__speakers {
        @mixin framed;
        padding: .75em;

        &::before,
        &::after {
            width: 30px;
            height: 30px;
            border-top-width: 3px;
            border-right-width: 3px;
            }

        &::before {
            top: 0;
            right: 0;
            }

        &::after {
            bottom: 0;
            left: 0;
            }
        }
        .podcast-info__speaker-card {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:first-child) {
                margin-top: 1rem;
                }

            /* Single cards hide speaker name */
            &:only-child {

                & .podcast-info__speaker-card-image {
                    flex-basis: 100%;
                    }

                & .podcast-info__speaker-card-name {
                    display: none;
                    }

                }
            }
            .podcast-info__speaker-card-image {
                position: relative;
                flex-basis: 80px;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 30px;
                    background: linear-gradient(to top, var(--brand-primary), transparent 100%);
                    z-index: 5;
                    }
                }
            .podcast-info__speaker-card-name {
                flex-basis: 100px;
                color: var(--brand-secondary);
                font-weight: 600;
                text-transform: uppercase;
                text-align: right;
                }

@media (width >= 600px) {

    .podcast-info {
        display: flex;
        align-items: flex-start;
        }
        .podcast-info__body {
            flex-grow: 1;
            padding-right: 2rem;
            }
        .podcast-info__speakers {
            flex: 0 0 33%;
            }

}

audio {
    display: block;
    width: 100%;

    &:focus {
        outline: 0;
        }
    }
