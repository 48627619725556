/** ------------------------------
    Custom select control
    ------------------------------ */
.custom-select-control {
    margin: var(--form-control-margin) 0;
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    background-image: url('../../img/inline/select-arrow.svg');
    background-position: calc(100% - 1.2em) 50%;
    background-repeat: no-repeat;
    background-size: 1.1em auto;

    &:matches(:focus-within)  {
        border-color: var(--form-control-default-border-color-active);
        }

    & > select {
        @mixin form-control;
        margin: 0;
        padding-right: 3rem;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        height: calc(var(--form-control-height) - (var(--form-control-border-width) * 2));
        font-size: 1rem;
        font-weight: 800;
        color: var(--form-control-default-color);
        text-transform: uppercase;

        &:focus {
            outline: 0;
            }

        &::-ms-expand {
            display: none;
            }
        }
    }


/** ------------------------------
    Custom choice control
    ------------------------------ */
.custom-choice-control {
    display: flex;
    align-items: center;
    margin: .5rem 0;

    & input {
        @mixin visually-hidden;
        z-index: -1;

        /* Focus */
        &:focus {

            & ~ label::before {
                border-color: var(--form-control-default-border-color-active);
                }

            }

        /* Checked */
        &:checked {

            & ~ label::before {
                border-color: transparent;
                background: #0064A8 url('../../img/inline/control-check.svg') no-repeat 50% 50%;
                background-size: 80% 80%;
                }

            }

        /* Required */
        &[required="required"]:not(.no-required) ~ label {

            &::after {
                content: "*";
                margin-left: .25em;
                color: var(--brand-red);
                }

            }

        /* Radio */
        &[type="radio"] ~ label {

            &::before {
                border-radius: 50%;
                }
            }

        }

    & label {
        position: relative;
        padding-left: 1.5rem;
        font-size: .875rem;
        font-weight: 800;
        line-height: 1.25;
        color: #0064A8;

        & a {
            text-transform: uppercase;
            color: #0064A8;
            }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 1.125rem;
            height: 1.125rem;
            margin-right: .75em;
            border-radius: 0;
            border: 2px solid #0064A8;
            background-color: #fff;
            transition: background-color .2s ease;
            }
        }

    }


/** ------------------------------
    Custom file control
    ------------------------------ */
.custom-file-control {
    margin: var(--form-control-margin) 0;
    }
    .custom-file-control__btn {
        position: relative;
        display: inline-block;

        & [type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            }
        }
