/** ------------------------------
    Modal
    ------------------------------ */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    &.is-open {
        display: block;
        }
    }
    .modal__overlay {
        @mixin container-gutters;
        padding-top: 20vh;
        padding-bottom: 20vh;
        width: 100%;
        height: 100%;
        background: rgba(4, 38, 65, .8);
        overflow-y: scroll;
        }
        .modal__container {
            margin: auto;
            width: 100%;
            max-width: 665px;
            padding: 1rem;
            background-color: var(--brand-primary);
            color: #fff;

            @media (width >= 800px) {
                padding: 2rem;
                }
            }
        .modal__title {
            text-transform: none;

            @media (width < 768px) {
                font-size: 1.25rem;
                }
            }
        .modal__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1.5rem;

            & .btn {
                min-width: 100px;

                @media (width >= 1000px) {
                    min-width: 165px;
                    }
                }

            @media (width < 500px) {
                flex-direction: column;
                align-items: stretch;

                & > * {
                    flex-basis: 100%;

                    &:not(:first-child) {
                        margin-top: 1rem;
                        }
                    }
                }
            }


/** ------------------------------
    Modifiers
    ------------------------------ */
.modal--light {

    & .modal__container {
        background-color: #fff;
        color: var(--brand-primary);
        }

    }


/** ------------------------------
    Vendor
    ------------------------------ */
[data-micromodal-trigger] {
    cursor: pointer;
    }
