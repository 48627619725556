/** ------------------------------
    Background video
    ------------------------------ */
.bg-video {
    position: relative;
    overflow: hidden;

    & > video {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        z-index: -1;
        user-select: none;
        pointer-events: none;
        mask-image: linear-gradient(to right, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .7) 60%);
        }
    }

.auth {

    & :matches(main, footer) {
        position: relative;
        z-index: 1;
        }

    & main {
        background-color: #3cb4e5;
        }

}
