/** ------------------------------
    Headshot card
    ------------------------------ */
.headshot-card {
    @mixin framed;
    position: relative;
    height: 100%;
    background-color: #eaeaea;

    & .callout {
        color: inherit;
        }

    @media (width >= 640px) {
        display: flex;
        }
    }
    .headshot-card__media {
        overflow: hidden;
        position: relative;

        @media (width >= 640px) {
            flex: 0 0 35%;
            }

        & > img {
            position: absolute;
            left: 0;
            bottom: 0;
            height: calc(100% - 1rem);
            max-width: none;
            transform: translateX(-15%);
            }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 20%;
            min-width: 30px;
            height: 100%;
            background: linear-gradient(to right, transparent, #eaeaea 90%);
            z-index: 5;
            }
        }
    .headshot-card__body {
        position: relative;
        z-index: 5;
        padding: 2rem;

        @media (width >= 640px) {
            padding-left: 0;
            }
        }
        .headshot-card__title {
            color: #0064A8;
            font-size: 2rem;
            text-transform: none;
            }
        .headshot-card__meta {
            color: var(--brand-secondary);
            }
