/** ------------------------------
    Page Hero
    ------------------------------ */
.page-hero {
    position: relative;
    background: linear-gradient(90deg, #3cb4e5, #3cb4e5 30%, rgba(60, 180, 229, .1) 55%);
    color: #fff;
    }
    .page-hero__container {
        @mixin container;
        @mixin framed;
        position: relative;
        min-height: 370px;

        /* Adjust frames for resolutions smaller than the container */
        @media (width < 1200px) {

            &::before {
                right: 2vw;
                }

            &::after {
                left: 2vw;
                }

            }

        }
    .page-hero__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        & :matches(img, video) {
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            max-width: none;
            height: 100%;
            mix-blend-mode: darken;
            }
        }
    .page-hero__body {
        flex-grow: 1;
        padding-top: 2rem;
        font-weight: 600;

        & > * {
            max-width: 600px;
            }

        @media (width >= 600px) {
            padding-left: 2vw;
            padding-right: 2vw;
            }

        @media (width >= 768px) {
            padding-top: 5.5rem;
            }
        }

    .page-hero__title {
        font-size: 2.5rem;
        font-weight: 800;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;

        & span {
            color: var(--brand-primary);

            &:first-child {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                display: block;
                margin-top: -40px;
                }
            }

        @media (width < 600px) {
            font-size: 2rem;
            }

        @media (width < 768px) {
            padding-top: 50px;

            & span:first-child {
                margin-top: -34px;
                font-size: 1.125rem;
                }
            }
        }
