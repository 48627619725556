/** ------------------------------
    Base
    ------------------------------ */
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--btn-padding);
    min-height: var(--btn-height);
    min-width: 80px;
    line-height: 1.2;
    border: 0;
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-weight: var(--btn-font-weight);
    color: var(--btn-default-color);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:focus {
        outline: 0;
        }
    }
    .btn__icon {
        width: 1.4em;
        height: 1.4em;
        margin-left: 1em;
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--block {
    width: 100%;
    }

.btn--sm {
    min-height: calc(var(--btn-height) * .75);
    font-size: .75rem;
    }

.btn--as-link {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: var(--brand-secondary);

    &:hover {
        color: #fff;
        }
    }

.btn--ghost {
    background-color: transparent;
    color: inherit;
    }

.btn--white {
    background-color: #fff;
    color: var(--brand-red);
    }

.btn--blue {
    background-color: var(--brand-secondary);
    }

.btn--disabled {
    background-color: #96979A;
    }

.btn--arrow {
    position: relative;
    padding-right: 3rem;

    & > img {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        }
    }

.btn--justify {
    justify-content: space-between;
    }


/** ------------------------------
    Wrappers
    ------------------------------ */
.btn-cta {
    margin-top: 2rem;
    }

.btn-group {

    & > * {
        width: 100%;
        }

    @media (width >= 500px) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > * {
            width: auto;
            }
        }

    }
