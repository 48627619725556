/** ------------------------------
    Country selector
    ------------------------------ */
.country-auth__enter {
    width: 100%;
    max-width: 260px;
    }

.country-auth__choice {

    @media (width < 680px) {

        & > * {
            margin-top: 1rem;
            }

        }

    @media (width >= 680px) {
        display: flex;
        align-items: center;

        & > * {
            flex-grow: 1;
            margin-top: 0;
            margin: 0 1rem;
            }

        & > :first-child {
            margin-left: 0;
            }

        & > :last-child {
            margin-right: 0;
            }
        }

    }

.country-selector {
    display: flex;
    align-items: center;

    & select {
        height: 2.25rem;
        line-height: 2.25rem;
        font-size: .875rem;
        }

    @media (width < 680px) {
        flex-direction: column;
        align-items: flex-start;
        }

    }
    .country-selector__btn {
        margin-left: 1rem;

        @media (width < 680px) {
            margin-top: 1rem;
            margin-left: 0;
            }

        & .btn {
            min-height: 2rem;
            }

        }

