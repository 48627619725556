/** ------------------------------
    Site Header
    ------------------------------ */
@custom-media --menu-collapsed (width < 768px);
@custom-media --menu-expanded (width >= 768px);

.site-header {
    position: relative;
    height: 80px;
    border-top: 3px solid var(--brand-red);
    background-color: var(--brand-primary);
    color: #fff;
    z-index: 99;

    & a {
        text-decoration: none;
        color: currentcolor;
        }
    }
    .site-header__container {
        @mixin container;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        @media (width < 600px) {
            padding: 0 .75rem;
            }
        }

        /* Partners */
        .site-header__partners {
            display: flex;
            align-self: flex-start;
            margin-top: 28px;

            & img {
                margin-left: 2rem;
                height: 25px;
                }

            @media (width < 930px) {
                display: none;
                }
            }

        /* Account menu */
        .site-header__account-menu {
            position: absolute;
            top: 0;
            right: 0;
            @mixin container-gutters;
            font-size: .925rem;
            font-weight: 400;
            color: #fff;
            line-height: 2rem;

            @media (--menu-collapsed) {
                display: none;
                }

            & a {
                color: currentcolor;

                &:hover {
                    text-decoration: underline;
                    }
                }
            }
            .site-header__account-nav {
                display: flex;
                margin: 0;
                }
                .site-header__account-nav-item {
                    display: flex;
                    align-items: center;

                    &:not(:last-child) {
                        margin-right: .75em;
                        }
                    }

                /* User account item */
                .site-header__account-nav-item--user {

                    & a {
                        padding: 0 1em;
                        background-color: rgba(255, 255, 255, .6);
                        color: var(--brand-primary);
                        font-weight: 600;
                        text-decoration: none;

                        &:hover {
                            background-color: #fff;
                            }
                        }

                    }

        /* Menu */
        .site-header__menu {
            text-transform: uppercase;
            font-weight: 600;
            }

    /* Toggle */
    .site-header__menu-toggle {
        @mixin button-reset;
        margin-left: auto;
        padding: .75rem;
        width: 3rem;
        height: 3rem;
        color: currentcolor;

        @media(--menu-expanded) {
            display: none;
            }
        }

    /* Back to Hub */
    .back-to-hub {
        display: flex;
        align-items: center;
        text-transform: none;

        & svg {
            width: 1rem;
            height: 1rem;
            margin-right: .5em;
            }
        }


@media (--menu-collapsed) {

    .site-header {
        flex-wrap: wrap;
        }
        .site-header__brand-link {

            & img {
                height: 55px;
                }

            }

        /* Menu */
        .site-header__menu {
            display: none;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: calc(100% - 30px);
            max-width: 450px;
            background: #c2d1de;
            border-top: 3px solid var(--brand-red);
            z-index: 5;

            & .site-header__menu-toggle {
                position: absolute;
                top: 15px;
                right: 0;
                padding: 0;
                line-height: 3rem;
                font-size: 2rem;
                }
            }
            .site-header__nav {
                padding: 70px 1rem 0;
                background-color: var(--brand-primary);

                & a {
                    position: relative;
                    display: block;
                    padding: 1.5rem 1rem;
                    font-weight: 800;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        width: 17px;
                        height: 12px;
                        background: url('../../img/inline/arrow-white.svg') no-repeat;
                        background-size: 100% auto;
                        }
                    }

                /* Back to Hub */
                & .back-to-hub {
                    display: flex;

                    &::after {
                        display: none;
                        }

                    }
                }
                .site-header__nav-item {

                    &:not(:first-child) {
                        border-top: 2px solid #c2d1de;
                        }

                    }
                }

                /* Account items */
                .site-header__nav-item--user {
                    margin-left: -1rem;
                    margin-right: -1rem;
                    border-bottom: 2px solid #c2d1de;
                    background-color: rgba(255, 255, 255, .6);
                    color: var(--brand-primary);

                    & a::after {
                        display: none;
                        }

                    & + * {
                        border-top: 0 !important;
                        }
                    }

    /* Active state */
    .site-header--menu-active {
        z-index: 9999; /* Force above VP */

        & .site-header__menu {
            display: block;
            }

        }


@media (--menu-expanded) {

    .site-header {
        height: 120px;
        }

    /* Menu */
    .site-header__menu {
        margin-left: auto;
        align-self: flex-end;
        }
        .site-header__nav {
            display: flex;
            margin-bottom: 0;
            }
            .site-header__nav-item {

                &:not(:first-child) {
                    margin-left: 3.5rem;
                    }

                &.current-menu-item a {
                    color: var(--brand-secondary);
                    border-bottom-color: var(--brand-red);
                    }

                & a {
                    display: block;
                    padding-bottom: 10px;
                    border-bottom: 6px solid transparent;

                    &:hover {
                        color: var(--brand-secondary);
                        border-bottom-color: var(--brand-red);
                        }
                    }

                /* Back to Hub */
                & .back-to-hub {
                    display: flex;
                    text-transform: none;
                    }

                }

            /* Hide account items */
            :matches(.site-header__nav-item--user, .site-header__nav-item--user ~ *) {
                display: none;
                }

}
