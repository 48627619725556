/** ------------------------------
    Progress card
    ------------------------------ */
.progress-card {
    display: flex;
    flex-direction: column;
    background-color: #eaeaea;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    color: #808080;
    text-align: center;

    & a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            }
        }
    }
    .progress-card__body {
        padding: 2rem 1rem;
        flex-grow: 1;
        }
        .progress-card__title {
            margin-top: 1rem;
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: 800;
            text-transform: uppercase;
            }
    .progress-card__action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
        background-color: grey;
        color: #fff;
        font-weight: 700;
        line-height: 1;

        & svg {
            margin-left: 1em;
            width: 2rem;
            height: 2rem;
            }
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.progress-card--locked {
    pointer-events: none;
    user-select: none;
    opacity: .6;

    & .progress-card__action {
        background-color: #808080 !important;
        color: transparent;
        }

    & .progress-donut {
        color: #808080;
        }

    }


/** ------------------------------
    Grid
    ------------------------------ */
.progress-card-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
        flex: 0 0 48.5%;
        margin-bottom: 3%;

        @media (width <= 500px) {
            flex-basis: 100%;
            }
        }
    }


/** ------------------------------
    Progress donut
    ------------------------------ */
.progress-donut {
    position: relative;
    margin: auto;
    width: 80px;
    text-transform: uppercase;
    font-weight: 700;
    color: #042641;

    & svg {
        width: 80px;
        height: 80px;
        }
    }
    .progress-donut__stat {
        position: absolute;
        top: 0;
        width: 100%;
        font-size: 1.25rem;
        line-height: 80px;
        text-align: center;
        }
    .progress-donut__label {
        margin-top: .5em;
        font-size: .75rem;
        }


/** ------------------------------
    Total progress
    ------------------------------ */
.total-progress {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.5rem;
    background-color: #0064A8;
    color: #fff;

    & .progress-donut {
        color: currentcolor;
        }
    }
    .total-progress__title {
        flex-grow: 1;
        margin-left: 1rem;
        margin-bottom: 0;
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: none;
        }
