/** ------------------------------
    Base
    ------------------------------ */
html {
    font: var(--base-font-weight) var(--base-font-size)/var(--base-line-height) var(--base-font-family);
    color: var(--base-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

::selection {
    background-color: red;
    color: white;
    }


/** ------------------------------
    Links
    ------------------------------ */
a {
    color: var(--brand-red);
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    }


/** ------------------------------
    Headings
    ------------------------------ */
h1, h2, h3, h4, h5, h6 {
    @mixin base-margin;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    line-height: 1.05;
    text-transform: uppercase;
    }

.hd-xl {
    font-size: 4rem;
    }

.hd-lg {
    font-size: 2.5rem;
    }

.hd-md {
    font-size: 1.5rem;
    }

.hd-bd {
    font-size: 1.125rem;
    }

.hd-sm {
    font-size: .85rem;
    }

.hd-xs {
    font-size: .75rem;
    }

.lead {
    font-size: 1.25rem;
    }

.hd-spaced {
    margin-top: 2.5rem;
    }

.callout {
    color: var(--brand-red);
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 800;
    }


/** ------------------------------
    Content
    ------------------------------ */
p {
    @mixin base-margin;
    }

ol,
ul {
    @mixin base-margin;
    }

b,
.strong {
    font-weight: 700;
    }
